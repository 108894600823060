import React from "react"
import css from "./stylesheets/NewsItem.module.scss"

export default props => {
  return (
    <div className={css.base}>
      <p className={css.date}>{props.date}</p>
      <div>{props.children}</div>
    </div>
  )
}
