import React from "react"
import { graphql } from "gatsby"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import NewsItem from "../../components/NewsItem"
import Seo from "../../components/Seo"
import CenterContentLayout from "../../components/CenterContentLayout"

export default ({ data }) => {
  let newsList = []
  for (let i = 0; i < data.allMarkdownRemark.edges.length; i += 1) {
    let node = data.allMarkdownRemark.edges[i].node
    newsList.push(
      <NewsItem key={node.id} date={node.frontmatter.date}>
        <div dangerouslySetInnerHTML={{ __html: node.html }} />
      </NewsItem>
    )
  }

  return (
    <div>
      <Seo title={`NEWS`} lang={`en`} />

      <LayoutBasic narrow={true} lang={`en`}>
        <BasicText>
          <CenterContentLayout narrow={true}>
            <h1>NEWS</h1>
            {newsList}
          </CenterContentLayout>
        </BasicText>
      </LayoutBasic>
    </div>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: { langKey: { eq: "en" } }
        fileAbsolutePath: { regex: "/(news)/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
          }
          fields {
            slug
            langKey
          }
          html
        }
      }
    }
  }
`
